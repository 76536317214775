import React from 'react';
import CurriculumSelector from '../../components/CurriculumSelector';
import { Column, Container, Row } from '../../components/Grid';
import Image from '../../components/Image';
import Layout from '../../components/Layout';
import Section from '../../components/Section';
import Video from '../../components/Video';
import Action from '../../components/Resource/Action';

const AIRevolutionPage = ({ data }) => (
  <Layout title="AI Revolution: Bridging Learners to Tomorrow's Workforce">
    <Section>
      <Container>
        <Row>
          <Column size={11}>
            <h1 className="page-header">
              AI Revolution: Bridging Learners to Tomorrow's Workforce
            </h1>
          </Column>
        </Row>
        <Row>
          <Column size={8}>
            <Video
              poster="forces-of-motion-placeholder.png"
              guidSrc="655047d2-1474-4486-b919-a1ae1bc441f9"
              title="The AI Revolution: Bridging Learners to Tomorrow's Workforce"
            />
          </Column>
          <Column size={4}>
            <p>
              Meet Steve Dembo, an educator bridging the gap between AI's future
              impact on work and its current role in education. In this master
              class he guides educators on how to prepare students for
              tomorrow's careers by leveraging AI in the classroom, ensuring
              they excel in an evolving landscape.
            </p>
          </Column>
        </Row>
      </Container>
    </Section>
    {/************** VTS GUIDE **************/}
    <Section>
      <Container>
        <Row className="educator-guide-forces">
          <Column size={4}>
            <Image filename="resources-image-AI-EdGuide.png" />
          </Column>
          <Column size={6} className="educator-guide-column">
            <h3>Educator Guide</h3>
            <p style={{ fontSize: '18px' }}>
              <b>Empowering Educators to Integrate AI for Future Learning</b>
            </p>
            <p>
              The educator guide serves as a comprehensive roadmap, equipping
              teachers with the tools and insights needed to seamlessly
              integrate AI into their educational practices, fostering a dynamic
              learning environment that prepares students for success in the
              digital age.
            </p>
          </Column>
          <Column size={2} className="bottom-button">
            <div className="resource__actions resource__actions--aligned">
              <Action
                label="Download"
                dropdown={true}
                dropdownItemClassname="vts__dropdown-items"
                actions={[
                  {
                    label: 'English',
                    download: '/pdfs/Caterpillar-AIClassroom-EdGuide.pdf',
                    metadata: '.PDF, 410KB',
                  },
                  {
                    label: 'Spanish',
                    download:
                      '/pdfs/Caterpillar-MasterClassVideo-EdGuide_ES.pdf',
                    metadata: '.PDF, 609KB',
                  },
                  {
                    label: 'French',
                    download:
                      '/pdfs/Caterpillar-MasterClassVideo-EdGuide_FR.pdf',
                    metadata: '.PDF, 632KB',
                  },
                ]}
              />
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
    {/************** QUOTE **************/}
    {/* <Section>
      <Container>
        <Row>
          <Column size="12" className="forces-quote">
            <Image
              filename="resources-graphic-quotemark.svg"
              className="start-slash"
            />
            <p className="forces-quote-text">
              AI, big data, machine learning, and automation will usher in a new
              and novel world full of interesting careers that don’t even exist
              today.
            </p>
            <Image
              filename="resources-graphic-quotemark.svg"
              className="end-slash"
            />
            <p className="forces-quote-author">– Kat, Host</p>
          </Column>
        </Row>
      </Container>
    </Section> */}
    {/************** AFTERSCHOOL ACTIVITIES **************/}
    {/* <Section>
      <Container>
        <Row className="section-image-header">
          <Column size={6}>
            <h2>After School Activity</h2>
          </Column>
          <Column size={6}>
            <Image filename="resources-graphic-lineart-ASactivity.svg" />
          </Column>
        </Row>
        <Row>
          <Column size={9}>
            <p>
              Explore the ways that the Laws of Motion can be harnessed in
              students’ everyday lives with a series of engaging activities that
              are easy to implement in any environment.
            </p>
          </Column>
        </Row>
        <Row>
          <DigitalLessonBundle
            resource1={
              <Resource
                img={'resources-energyandforces-image-ASactivity@2x.png'}
                subject={'Afterschool Activity'}
                title={'Sensing and Communication'}
                className="magnus-effect"
                description="Uncover the mysteries of the Magnus Effect as students learn how this unique phenomenon of motion is used to achieve amazing effects in sports, engineering and beyond. Kickstart creative problem-solving with a series of hands-on student activations that put the fundamentals of motion into action."
                actions={[
                  {
                    label: 'Download',
                    download:
                      '/pdfs/Caterpillar-AfterschoolExtension-ReadySetGoal.pdf',
                    metadata: '.PDF, 509KB',
                  },
                ]}
              />
            }
            resource2={
              <Resource
                img={'global-image-ASFacilitatorguide@2x.png'}
                title={'Facilitator Guide'}
                className="facilitator-guide"
                description="Students learn the fundamentals of physics and how to solve problems by tackling real-world challenges in out of class and after-school sessions. Through a process of investigation, team discussion, and hands-on experiences, students will advance theory into applications they can see and touch."
                actions={[
                  {
                    label: 'Download',
                    download: '/pdfs/Caterpillar-AfterschoolGuide.pdf',
                    metadata: '.PDF, 606KB',
                  },
                ]}
              />
            }
            className="dlb-row"
          />
        </Row>
      </Container>
    </Section> */}
    {/************** CURRICULUM SELECTOR **************/}
    <Section>
      <Container>
        <CurriculumSelector />
      </Container>
    </Section>
    {/************** TESTIMONIAL **************/}
    <Section>
      <Container>
        <Row>
          <Column>
            <Image filename="resources-image-testimonial.png" />
          </Column>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default AIRevolutionPage;
